.view-login {
  grid-column: 1 / 3 !important;
  grid-row: 1 / 2 !important;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 0 !important;

  .logo {
    margin: var(--spacing-wide);
    width: 150px;
    max-width: 90%;
  }

  input {
    box-shadow: var(--color-gray) 0 0 var(--spacing-small) inset !important;
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: hidden;
  }
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;

  max-width: 450px;
  width: 90%;
}

@media(max-width: 500px) {

  .view-login {
    .logo {
      position: fixed;
      left: var(--spacing-small);
      bottom: var(--spacing-small);
    }
  }
}