form {
  .input-group {
    label {
      white-space: nowrap;
    }
    
    input, label {
      display: block;
    }
  }
}

