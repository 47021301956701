nav {
  display: flex;

  section > div {
    display: flex;
  }
}

@media(max-width: 560px) {
  nav {
    position: fixed;
    bottom: 0;
    left: var(--spacing);
    width: calc(100% - (2 * var(--spacing)));
    height: 50px;
    z-index: 10;

    align-items: center;
    justify-content: center;

    background-color: rgba(255,255,255,.8);

    box-shadow: 0 0 var(--spacing-small) rgba(0, 0, 0, .5);
    border-top-left-radius: var(--spacing);
    border-top-right-radius: var(--spacing);

    flex-direction: row;

    section, .navigation-group {
      display: flex;
    }

    header {
      display: none;
    }

    section {
      > div {
        flex-direction: row;
      }
    }
  }
}

@media(min-width: 560px) {

  nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 var(--spacing-small) var(--color-gray);

    section > div{
      flex-direction: column;
      align-items: center;
    }

    header, .navigation-group {
      border-bottom: 1px solid var(--color-gray);
    }
  }

}