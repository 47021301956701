.scrollbar
{
  &::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  }
  
  &::-webkit-scrollbar
  {
    height: 4px;
    width: 4px;
    background-color: var(--color-gray);
  }
  
  &::-webkit-scrollbar-thumb
  {
    background-color: var(--color-gray);
  }
  

  &.native {
    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 10px;
      background-color: #F5F5F5;
    }
    
    &::-webkit-scrollbar
    {
      width: 12px;
      background-color: #F5F5F5;
    }
    
    &::-webkit-scrollbar-thumb
    {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: #555;
    }
    
  }
}