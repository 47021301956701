.btn {
  width: 40px;
  height: 40px;
  color: var(--color-gray);
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin: var(--spacing-small);
  border-radius: 50%;

  &.primary {
    color: white;
    background-color: var(--color-primary);
  }

  &.disabled {
    cursor: not-allowed;
    opacity: .5;
  }

  &.enabled {
      cursor: pointer;

      &:hover {
        font-size: 1.2em;
      }
  }
}