:root {
  --spacing: 1rem;
  --spacing-small: .25rem;
  --spacing-wide: 2rem;

  --color-blue: #0070C0;
  --color-red: #DD2A1B;
  --color-gray: #8D8F91;

  --color-error: #B93700;
  --color-info: #609CBD;

  --color-primary: var(--color-blue);

}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5 {
  line-height: 1em;
}

.color-error { color: var(--color-error); }
.color-info { color: car(--color-info); }

body, html {
  height: 100%;
  height: calc(100%);
  height: 100vh;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  overflow: hidden;
}

.App {
  height: 100%;
  height: calc(100%);
  height: 100vh;

  > header {
    background-color: var(--color-red);
    // background-color: rgba(255,255,255,.3);
    color: white;
    font-weight: bold;
    margin: 0;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-left, .header-right {
      height: 100%;
      display: flex;
      align-items: center;
    }

    .header-left {

      h1 {
        margin: 0 0 0 var(--spacing);
        font-size: 1em;
      }
  
      .logo {
        height: calc(100% - calc(var(--spacing-small) * 4));
        margin-left: calc(var(--spacing-small) * 2);
      }
    }

    .header-right {

    }


    box-shadow: 0 0 var(--spacing-small) rgba(0, 0, 0, .5);
  }

  > main {
    height: 100%;
    height: calc(100%);
    height: 100vh;
    
    overflow-y: auto;
  
    > * {
      margin: var(--spacing-small);
    }

    padding: var(--spacing-small) var(--spacing-small) 110px;
    box-sizing: border-box;
  }
  
}


@media(min-width: 560px) {
  .App {
    display: grid;
    grid-template-columns: 40px calc(100% - 40px);
    grid-template-rows: 40px calc(100% - 40px);
    grid-template-rows: 40px calc(100vh - 40px);

    > nav {
      grid-column: 1;
      grid-row: 2 / 2;
    }

    > header {
      grid-column: 1 / 3;
      grid-row: 1 / 2;
    }

    > main {
      grid-column: 2 / 2;
      grid-row: 2 / 2;
      // box-shadow: 0 0 var(--spacing-small) rgba(0, 0, 0, .5);
    }
  }
}