form {
  display: flex;

  .input-group-container {
    display: flex;
  }

  .input-group {
    width: 100%;
    padding: var(--spacing-small);
    box-sizing: border-box;
    display: flex;
    align-items: center;

    &.col {
      flex-direction: column;
    }
  }

  .button-wrapper {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: var(--spacing);
  }

  label {
    color: var(--color-gray);
    font-size: .8em;
    margin: var(--spacing-small) var(--spacing-small) var(--spacing-small) 0;
  }

  input, label {
    display: block;
  }

  input {
    &.error {
      border-color: var(--color-error);
      color: var(--color-error);
      font-weight: bold;
    }
  }

  input {
    &[type="text"], &[type="password"], &[type="email"] {
      padding: var(--spacing) var(--spacing-small);
    }
    box-sizing: border-box;
    border: 1px solid var(--color-gray);
    // border-bottom-style: hidden;
  }

  input[type="checkbox"] {
    max-width: 40px;
  }

  fieldset {
    legend {
      text-transform: uppercase;
      font-weight: bold;
      margin-left: var(--spacing-small);
      padding-left: var(--spacing-small);
      padding-right: var(--spacing-small);
    }

    border: 1px solid var(--color-gray);
    // border-left: 0;
    // border-right: 0;
    // border-top: 0;
    // border-bottom: 0;

    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
 
    margin: var(--spacing-small);

    display: flex;
  }
}

@media(max-width: 760px){
  form {
    width: 100%;
    flex-direction: column;
    align-items: center;
 
    input, label {
      display: block;
      width: 100%;
    }

    fieldset {
      align-self: normal;
      margin: 0;
      border-top: 1px solid var(--color-gray);
      border-right: 0;
      border-bottom: 0;
      border-left: 0;
    }
  }
}