.form-login {
  box-shadow: 0 0 10px var(--color-gray);
  background-color: white;

  width: 90%;
  max-width: 350px;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: var(--spacing);
  box-sizing: border-box;
  border-radius: var(--spacing);

  .title {
    font-size: 1.2em;
    margin-bottom: var(--spacing);
    color: var(--color-gray);
    text-transform: uppercase;
  }

  input, label {
    text-align: center;
  }

  input {
    border-radius: var(--spacing);
    box-shadow: 0 0 5px var(--color-gray);
  }

  label {
    text-align: center;
  }
}

form {

  .btn-submit {
    margin-top: var(--spacing-small);
    border: 0;
    cursor: pointer;
  }

}