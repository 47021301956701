.widget {
  box-shadow: grey 0px 0px var(--spacing-small);
  background-color: rgba(255,255,255,.6);
  width: calc(100% - calc(2 *var(--spacing-small)));

  header {
    background-color: white;
    display: flex;
    height: 30px;
    padding: 0 10px;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(-200deg, rgba(255,255,255,1) 0%, rgba(141,143,145,1) 100%)
  }
}